// Variables
$primary-color: #915832;
$secondary-color: #064C37;
$primary-hover-color: #814f2d;
$error-color: #ff6b6b;
$font-family: 'Poppins',
Helvetica,
Arial,
sans-serif;

// Mixin for flex center
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// Payment Form Styles
#payment-form {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 650px;
    margin: 40px auto;


    label {
        color: $secondary-color;
    }
}

#payment-form .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

#payment-form .col-sm-6 {
    flex: 1;
    min-width: 200px;
    margin-bottom: 15px;
}

#payment-form .form-group {
    margin-bottom: 15px;
}

#card-amount {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: $secondary-color;
}

#card-element {
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    color: $secondary-color;
}

.StripeElement--focus {
    border-color: $primary-color;
}

.StripeElement--invalid {
    border-color: $error-color;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#card-errors {
    color: $error-color;
    margin-top: 10px;
    font-size: 14px;
}

#card-button {
    background-color: $primary-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;

    &:hover {
        background-color: $primary-hover-color;
    }

    i {
        margin-right: 10px;
    }
}

// Responsive Styles
@media (max-width: 600px) {
    #payment-form {
        padding: 15px;
    }

    #card-button {
        font-size: 14px;
    }
}